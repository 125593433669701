<template>
    <div>
      <h1>Permission Denied</h1>
      <p>You don't have the required permissions to access this resource.</p>
      <!-- You can customize the content further based on your application's needs -->
    </div>
  </template>

  <script>
  export default {
    // Component logic goes here
  }
  </script>

  <style scoped>
  /* Component styles go here */
  </style>
